import React from "react";
import { Link } from "react-router-dom";

function LinkButton(props) {
  const { type, url, text, onClick, disabled } = props;

  const commonClasses =
    "rounded-md px-6 py-2.5 text-sm font-semibold text-brand-primary hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
  const disabledClasses = "opacity-50 cursor-not-allowed";

  if (type === "href") {
    return (
      <div className="col-span-full">
        <Link
          to={disabled ? "#" : url} // Prevent navigation if disabled
          className={`${commonClasses} ${disabled ? disabledClasses : ""}`}
          onClick={(e) => {
            if (disabled) e.preventDefault(); // Block navigation if disabled
          }}
        >
          {text}
        </Link>
      </div>
    );
  } else if (type === "function") {
    return (
      <div className="col-span-full">
        <button
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          className={`${commonClasses} ${disabled ? disabledClasses : ""}`}
        >
          {text}
        </button>
      </div>
    );
  } else {
    return null; // Return null or some fallback UI
  }
}

export { LinkButton };
