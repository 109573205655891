import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/textInput";
import { TextArea } from "../../../components/textArea";
import { Button } from "../../../components/button";
import { Toast } from "../../../components/toast";
import { SideSheet } from "../../../components/sheet/sideSheet";
import { CheckpointsList } from "../checkpoints/list";
import { SelectCheckpoints } from "../checkpoints/selectCheckpoints";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { SplitButton } from "../../../components/button/split";

const schema = z.object({
  tour_name: z.string().min(1, "Tour name is required"),
  tour_description: z.string().min(1, "Tour description is required"),
});

function TourDetails({
  isOpen,
  toggleOpen,
  setTours,
  tours,
  isAddMode,
  selectedTourId,
  toggleCheckpointsOpen,
  setSelectedTourCheckpoints,
  selectedTourCheckpoints,
  selectedTourDetails,
  fetchSelectedTourDetails,
  fetchToursList,
}) {
  const params = useParams();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      tour_name: "",
      tour_description: "",
    },
    resolver: zodResolver(schema),
  });

  const { reset, handleSubmit, formState } = methods;

  useEffect(() => {
    if (isOpen) {
      reset({
        tour_name: "",
        tour_description: "",
      });
    }
  }, [isOpen, reset]);

  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const onSubmit = (data) => {
    return isAddMode ? createTour(data) : updateTour(data);
  };

  const handleSave = handleSubmit(onSubmit);

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  const createTour = (data) => {
    const payload = {
      ...data,
      location_id: params.id,
      checkpoints: selectedTourCheckpoints,
    };

    fetch(`${process.env.REACT_APP_API_URL}/v1/locations/${params.id}/tours/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        setSelectedTourCheckpoints([]);
        fetchToursList();
        handleResponse("Tour created successfully", "success");
        reset();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create tour", "danger");
      });
  };

  const updateTour = (data) => {
    const payload = {
      ...data,
      location_id: params.id,
      checkpoints: selectedTourCheckpoints,
    };

    console.log("payload", payload);

    fetch(
      `${process.env.REACT_APP_API_URL}/v1/locations/tours/${selectedTourId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        fetchToursList();
        handleResponse("Tour updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update tour", "danger");
      });
  };

  const [isSelectCheckpointsOpen, setSelectCheckpointsOpen] = useState(false);
  const toggleSelectCheckpointsOpen = () => {
    setSelectCheckpointsOpen(!isSelectCheckpointsOpen);
  };

  const handleSetCheckpoints = (selectedCheckpoints) => {
    setCheckpoints(selectedCheckpoints);
    setSelectedTourCheckpoints(selectedCheckpoints);
  };

  const fetchTourDetails = async () => {
    if (!isAddMode && selectedTourId) {
      fetchSelectedTourDetails();
      const { tour_name, tour_description } = selectedTourDetails;

      reset({
        tour_name,
        tour_description,
      });
    }
  };

  useEffect(() => {
    if (selectedTourDetails) {
      reset({
        tour_name: selectedTourDetails.tour_name,
        tour_description: selectedTourDetails.tour_description,
      });
    }
  }, [selectedTourDetails, reset]);

  useEffect(() => {
    fetchTourDetails();
  }, [selectedTourId, isAddMode]);

  const [removeCheckpointId, setRemoveCheckpointId] = useState({});
  function handleRemoveCheckpoint(identifier, id) {
    const updatedCheckpoints = checkpoints.filter((c) => c.id !== id);

    setSelectedTourCheckpoints(updatedCheckpoints);
  }

  const [checkpoints, setCheckpoints] = useState([]);
  useEffect(() => {
    const fetchCheckpoints = async () => {
      if (!isAddMode && selectedTourId != null) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/v1/locations/tours/${selectedTourId}/checkpoints`
          );
          if (response.ok) {
            const data = await response.json();
            setCheckpoints(data);
            setSelectedTourCheckpoints(data);
          } else {
            console.error("Error fetching checkpoints");
          }
        } catch (error) {
          console.error("Error fetching checkpoints", error);
        }
      } else {
        setCheckpoints([]);
        setSelectedTourCheckpoints([]);
      }
    };

    fetchCheckpoints();
  }, [selectedTourId, isAddMode]);

  useEffect(() => {
    setCheckpoints(selectedTourCheckpoints);
  }, [selectedTourCheckpoints]);

  useEffect(() => {
    if (isAddMode) {
      setCheckpoints([]);
    }
  }, [isAddMode]);

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={isOpen}
          onClose={toggleOpen}
          title={!isAddMode ? "Tour details" : "Create tour"}
          isAddMode={isAddMode}
          footerText="Create tour"
          onError={onError}
          handleSave={handleSave}
          size="large"
        >
          <div className="flex-1 px-4 sm:px-6 py-6">
            <div className="space-y-3">
              <TextInput name="tour_name" label="Tour name" />
              <TextArea name="tour_description" label="Tour description" />
              <div>
                <div className="flex justify-between align-center mb-4">
                  <label
                    htmlFor="first-name"
                    className="mt-1 block text-sm font-medium leading-6 text-gray-900"
                  >
                    Checkpoints
                  </label>
                  <SplitButton
                    items={[
                      {
                        name: "Add checkpoint",
                        onClick: toggleSelectCheckpointsOpen,
                      },
                      {
                        name: "Create checkpoint",
                        onClick: toggleCheckpointsOpen,
                      },
                    ]}
                  />
                </div>
                <CheckpointsList
                  checkpoints={checkpoints}
                  setCheckpoints={(updatedCheckpoints) => {
                    setCheckpoints(updatedCheckpoints);
                    setSelectedTourCheckpoints(updatedCheckpoints);
                  }}
                  handleRemoveItem={handleRemoveCheckpoint}
                />
                <SelectCheckpoints
                  isOpen={isSelectCheckpointsOpen}
                  toggleOpen={toggleSelectCheckpointsOpen}
                  existingCheckpoints={checkpoints}
                  tourId={selectedTourId}
                  handleSetCheckpoints={handleSetCheckpoints}
                />
              </div>
            </div>
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}

export { TourDetails };
