import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const CheckboxGroup = ({
  name,
  label,
  options,
  layout = "vertical",
  disabledOptions = [],
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const layoutClass =
    layout === "horizontal" ? "flex-row space-x-4" : "flex-col space-y-2";

  return (
    <div className="sm:col-span-3">
      {label && (
        <label className="block text-sm font-regular leading-6 text-gray-700">
          {label}
        </label>
      )}
      <div className={`flex ${layoutClass}`}>
        {options.map((option) => (
          <div key={option.value} className="flex items-center">
            <input
              type="checkbox"
              id={option.value}
              value={option.value}
              {...register(name)} // Register with react-hook-form
              disabled={
                disabledOptions.includes(option.value) &&
                !getValues(name)?.includes(option.value)
              } // Disable if in disabledOptions and not selected
              className={`focus:ring-brand-primary h-4 w-4 text-indigo-600 border-gray-300 rounded ${
                disabledOptions.includes(option.value) ? "bg-gray-100" : ""
              }`}
            />
            <label
              htmlFor={option.value}
              className="ml-3 block text-sm text-gray-700"
            >
              {option.displayName}
            </label>
          </div>
        ))}
      </div>
      {errors[name] && (
        <p className="mt-2 text-sm text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export { CheckboxGroup };
