import React, { useEffect, useState } from "react";

const SegmentedSwitch = ({ optionOne, optionTwo, onChange, selected }) => {
  const [currentSelection, setCurrentSelection] = useState(selected);

  useEffect(() => {
    setCurrentSelection(selected);
  }, [selected]);

  const handleSelect = (option) => {
    setCurrentSelection(option);
    onChange(option);
  };

  return (
    <div className="text-sm border-2 border-gray-100 rounded-md w-fit overflow-hidden flex">
      <button
        className={`flex-1 py-1.5 px-4 ${
          currentSelection === optionOne.value
            ? "bg-white text-gray-500 font-semibold"
            : "bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-800 transition-colors duration-200"
        }`}
        onClick={() => handleSelect(optionOne.value)}
      >
        {optionOne.text}
      </button>
      <button
        className={`flex-1 py-1.5 px-4 ${
          currentSelection === optionTwo.value
            ? "bg-white text-gray-500 font-semibold"
            : "bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-800 transition-colors duration-200"
        }
        }`}
        onClick={() => handleSelect(optionTwo.value)}
      >
        {optionTwo.text}
      </button>
    </div>
  );
};

export default SegmentedSwitch;
