import React, { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/app-loading.json";

const TableLoadingAnimation = ({ isLoading, nonAnimated }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (nonAnimated) {
    defaultOptions.autoplay = false;
  }

  if (!isLoading) return null;

  return (
    <>
      <div className="flex items-center justify-center lg:min-h-[300px] md:min-h-[150px]">
        <Lottie options={defaultOptions} height={70} width={70} />
      </div>
    </>
  );
};

export { TableLoadingAnimation };
