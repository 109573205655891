import React, { useEffect, useState, useContext } from "react";
import { Tabs } from "../../../components/tabs";
import { AuthContext } from "../../../contexts/AuthContext";
import SegmentedSwitch from "../../../components/segmentedSwitch";
import { PrimaryButton } from "../../../components/button/primary";
import { StatusIndicator } from "../../../components/statusIndicator";
import { Banner } from "../../../components/banner";
import FormatPrice from "../../../utils/FormatPrice";

const intervalOptions = {
  optionOne: {
    text: "Monthly",
    value: "month",
  },
  optionTwo: {
    text: "Yearly",
    value: "year",
  },
};

function Plan() {
  const { authContext } = useContext(AuthContext);

  const {
    organization: { orgId, stripeCustomerId, planStatus, planTrialEnd },
  } = authContext;
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [backendData, setBackendData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(false);

  const handleIntervalChange = (interval) => {
    console.log("Selected interval:", interval);
    setSelectedInterval(interval); // Update the selected interval
  };

  const getSelectedIntervalFromData = (data) => {
    console.log("Data:", data);
    for (const product of data.products) {
      if (product.subscribed && product.subscribedPriceId) {
        // Find if the subscribed price is "monthly" or "yearly"
        console.log("Product:", product);
        const subscribedPrice = Object.entries(product.prices).find(
          ([interval, price]) => price.id === product.subscribedPriceId
        );

        if (subscribedPrice) {
          console.log("Subscribed price:", subscribedPrice[0]);
          return subscribedPrice[0]; // "monthly" or "yearly"
        }
      }
    }
    return null; // Return null if no subscription is found
  };

  // `${process.env.REACT_APP_API_URL}/v1/organizations/47/subscription?stripeId=cus_RDJdE94Bi7H2v9`
  useEffect(() => {
    console.log("Org ID:", orgId);
    console.log("Stripe customer ID:", stripeCustomerId);
    console.log("Auth context", authContext);
  }, [orgId, stripeCustomerId, authContext]);

  const fetchProductsAndSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/subscription?stripeId=${stripeCustomerId}`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      const reversedData = {
        ...data,
        products: data.products.reverse(),
      };

      setBackendData(reversedData);
      console.log("Backend data:", reversedData);

      const selectedInterval = getSelectedIntervalFromData(reversedData);
      setSelectedInterval(selectedInterval);
      console.log("Selected interval:", selectedInterval);

      setInitialLoadCompleted(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products and subscription:", error);
      setIsLoading(false); // Ensure loading state is cleared on error
    }
  };

  useEffect(() => {
    if (orgId && stripeCustomerId) {
      fetchProductsAndSubscription();
    }
  }, [orgId, stripeCustomerId]);

  /// Setup a useEffect to set the default value of selectedInterval based on the data
  useEffect(() => {
    /// Chec the interval values from the backendData and set the selectedInterval
  }, [backendData]);

  useEffect(() => {
    console.log("Selected interval:", selectedInterval);
  }, [selectedInterval]);

  return (
    <div>
      <Tabs current="Plan" section="settings" />
      <main className="py-6 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl bold">Subscription</h2>
          </div>
          {typeof backendData === "undefined" || isLoading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <div className="mb-4">
                <SegmentedSwitch
                  optionOne={intervalOptions.optionOne}
                  optionTwo={intervalOptions.optionTwo}
                  onChange={handleIntervalChange}
                  selected={selectedInterval} // Pass selected state
                />
              </div>
              {/* {
                // Display the trial end date if the organization is on a trial
                planStatus === "trialing" && (
                  <p className="text-sm text-gray-600 pt-4">
                    Your trial ends on{" "}
                    {new Date(planTrialEnd).toLocaleDateString()}
                  </p>
                )
              } */}
              <div className="pt-2">
                <Banner body="Unlock more features" />
              </div>
              <div className="flex flex-row gap-8 pt-6 text-gray-600">
                {backendData.products && backendData.products.length > 0 ? (
                  backendData.products.map((item) => {
                    const availableIntervals = Object.entries(item.prices);

                    // Determine the price for the selected interval or fall back
                    const selectedPrice =
                      item.prices[selectedInterval] ||
                      (availableIntervals.length === 1 &&
                        availableIntervals[0][1]); // Use the only price available if there's just one interval.

                    return (
                      <div
                        key={item.id}
                        className="flex flex-col w-1/4 bg-gray-50 p-4 rounded-lg"
                      >
                        <div>
                          <h3 className="text-sm">{item.name}</h3>
                          {selectedPrice && (
                            <div className="flex items-center space-x-2">
                              {/* Price */}
                              <span className="text-xlg font-extrabold">
                                {selectedPrice.amount === 0
                                  ? "FREE"
                                  : FormatPrice(
                                      selectedPrice.amount,
                                      selectedPrice.currency
                                    )}
                              </span>

                              {/* Status Indicator */}
                              {item.subscribed && (
                                <div className="pt-1 pl-2">
                                  <StatusIndicator
                                    type="success"
                                    text="Subscribed"
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <ul className="text-sm mt-2 mb-4">
                            {item.features.map((feature) => (
                              <li key={feature.name} className="pb-2">
                                {feature.name}
                              </li>
                            ))}
                          </ul>

                          <PrimaryButton
                            disabled={item.subscribed === true}
                            label={
                              item.subscribed
                                ? "Manage subscription"
                                : "Subscribe"
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No results found.</p>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export { Plan };
