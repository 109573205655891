import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { TextInput } from "../../../components/textInput";
import { CustomerSelectMenu } from "../../../components/select/customer";
import { ImageInput } from "../../../components/imageInput";
import { CheckboxGroup } from "../../../components/checkboxGroup";
import { SideSheet } from "../../../components/sheet/sideSheet";
import { Toast } from "../../../components/toast";
import { useForm, FormProvider, set } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const createSchema = (currentGuardsCount, maxGuards) =>
  z.object({
    first_name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    email: z
      .string()
      .email("Invalid email address")
      .min(1, "Email address is required"),
    phone_number: z.string(),
    customers: z
      .array(z.number())
      .min(1, "At least one customer must be selected"),
    roles: z
      .array(z.string())
      .nonempty("At least one role must be selected")
      .refine(
        (roles) => {
          const selectedGuardsCount = roles.filter(
            (role) => role === "guard:access"
          ).length;

          // Allow unselecting or reducing the number of guards
          if (selectedGuardsCount <= currentGuardsCount) {
            return true;
          }

          // Allow selecting guards only if it doesn't exceed the max
          return selectedGuardsCount <= maxGuards;
        },
        {
          message: `You cannot exceed the maximum of ${maxGuards} guards.`,
        }
      ),
  });

function UserDetails({
  isOpen,
  toggleOpen,
  selectedUserId,
  isAddMode,
  onSuccess,
  allowedGuards,
  currentGuardCount,
  updateGuardCount,
}) {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;
  const authOrgId = authContext.organization.authOrgId;
  const [roles, setRoles] = useState([]);
  const [isLoadingOrgRoles, setIsLoadingOrgRoles] = useState(true);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      customers: [],
      profile_url: "",
      auth_id: "",
      roles: [],
    },
    resolver: zodResolver(createSchema(currentGuardCount, allowedGuards)),
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (isOpen) {
      reset({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        customers: [],
        profile_url: "",
        auth_id: "",
        roles: [],
      });
    }
  }, [isOpen, reset]);

  /// Get the organization roles ///
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/auth/roles`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch roles: ${response.statusText}`);
        }

        const data = await response.json();

        // Safely map roles
        if (data && Array.isArray(data.roles)) {
          const rolesWithDisplayNames = data.roles.map((role) => ({
            value: role.key,
            displayName:
              role.key === "admin:access"
                ? "Admin"
                : role.key === "guard:access"
                ? "Guard"
                : role.name, // Fallback for unknown roles
          }));

          setRoles(rolesWithDisplayNames);
          setIsLoadingOrgRoles(false);
        } else {
          console.warn("Roles data is not in the expected format:", data);
          setRoles([]); // Fallback to an empty array
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        setRoles([]); // Fallback to an empty array
      }
    };

    fetchPermissions();
  }, []);

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const [clearImageTrigger, setClearImageTrigger] = useState(false);

  const handleImageClear = () => {
    setClearImageTrigger((prev) => !prev);
  };

  const clearForm = () => {
    handleImageClear(); // Clear the image
    reset({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      customers: [],
      profile_url: "",
      auth_id: "",
      roles: [],
    });
  };

  useEffect(() => {
    console.log("currentGuardCount: ", currentGuardCount);
  }, [currentGuardCount]);

  useEffect(() => {
    console.log("allowedGuards: ", allowedGuards);
  }, [allowedGuards]);

  const onSubmit = async (data) => {
    const saveFunction = isAddMode ? createUser : updateUser;
    await saveFunction(data);

    // Update the guard count only if the roles include 'guard:access'
    if (data.roles.includes("guard:access")) {
      const newGuardCount =
        currentGuardCount +
        (isAddMode ? 1 : backendData.roles.includes("guard:access") ? 0 : 1);
      updateGuardCount(newGuardCount);
    }
  };

  const handleSave = handleSubmit(onSubmit);

  /// Images ///
  const [imageFile, setImageFile] = useState(null);

  function createUser(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== "customers" && key !== "roles") {
        formData.append(key, data[key]);
      }
    });

    data.customers.forEach((customer, index) => {
      formData.append(`customers[${index}]`, customer);
    });

    data.roles.forEach((role, index) => {
      formData.append(`roles[${index}]`, role);
    });

    if (imageFile) {
      formData.append("file", imageFile);
    }
    formData.append("org_id", orgId);
    formData.append("auth_org_id", authOrgId);
    formData.append("auth_id", backendData.auth_id);

    fetch(`${process.env.REACT_APP_API_URL}/v1/users/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(`Fetch returned status ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        handleResponse("User created successfully", "success");
        onSuccess();
        clearForm();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create user", "danger");
      });
  }

  /// Update user ///
  function updateUser(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== "customers" && key !== "roles") {
        formData.append(key, data[key]);
      }
    });

    data.customers.forEach((customer, index) => {
      formData.append(`customers[${index}]`, customer);
    });

    data.roles.forEach((role, index) => {
      formData.append(`roles[${index}]`, role);
    });

    if (imageFile) {
      formData.append("file", imageFile);
    }

    formData.append("org_id", orgId);
    formData.append("auth_org_id", authOrgId);
    formData.append("auth_id", backendData.auth_id);

    fetch(`${process.env.REACT_APP_API_URL}/v1/users/${selectedUserId}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        handleResponse("User updated successfully", "success");
        onSuccess();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update user", "danger");
      });
  }

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  const [backendData, setBackendData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    customers: [],
    profile_url: "",
    auth_id: "",
    roles: [],
  });
  const [isLoadingBackendData, setIsLoadingBackendData] = useState(false);

  const fetchUserDetails = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/${id}?auth_org_id=${authOrgId}&detail=detailed`
      );
      if (!response.ok) throw new Error("Failed to fetch user details");

      const data = await response.json();
      const {
        first_name,
        last_name,
        email,
        phone_number,
        customers,
        profile_url,
        auth_id,
        roles,
      } = data;

      const parsedCustomers = customers?.[0]?.name ? customers : [];

      return {
        first_name,
        last_name,
        email,
        phone_number,
        customers: parsedCustomers.map((customer) => customer.id),
        profile_url,
        auth_id,
        roles: roles || [], // Ensure roles default to an empty array if undefined
      };
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!isAddMode && selectedUserId !== null) {
      fetchUserDetails(selectedUserId).then((details) => {
        if (details) {
          setBackendData(details);
          setIsLoadingBackendData(false);
          reset(details);
        }
      });
    }
  }, [isAddMode, selectedUserId, authOrgId, reset]);

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /// Image upload ///
  function handleImageChange(event) {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }

    setImageFile(file);
  }

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={open}
          onClose={toggleOpen}
          title={!isAddMode ? "User details" : "Create user"}
          footerText={isAddMode ? "Create User" : "Update User"}
          onError={onError}
          handleSave={handleSave}
        >
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-4">
              {!isLoadingBackendData && !isLoadingOrgRoles && (
                <>
                  <TextInput name="first_name" label="First name" />
                  <TextInput name="last_name" label="Last name" />
                  <TextInput name="email" label="Email address" />
                  <TextInput name="phone_number" label="Phone number" />
                  <CustomerSelectMenu
                    name="customers"
                    label="Customer access"
                  />
                  <ImageInput
                    name="userImage"
                    label="Upload image"
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
                    maxFileSize={3201010}
                    existing={isAddMode ? null : backendData.profile_url}
                    onClear={clearImageTrigger} // Passing the state directly
                  />
                  <CheckboxGroup
                    name="roles"
                    label="Roles"
                    options={roles}
                    layout="horizontal"
                    disabledOptions={
                      currentGuardCount >= allowedGuards ? ["guard:access"] : []
                    }
                  />
                </>
              )}
            </div>
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}

export { UserDetails };
