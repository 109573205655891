import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Button } from "../../components/button";
import { TextInput } from "../../components/textInput";
import { Toast } from "../../components/toast";
import { SideSheet } from "../../components/sheet/sideSheet";
import { ImageSelectMenu } from "../../components/select/image";
import { SearchLocationInput } from "../../components/textInput/searchLocationInput";
import { EmergencyContacts } from "./emergencyContacts";
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

/// Form validation schema ///
const schema = z.object({
  location_name: z.string().min(1, "Location name is required"),
  location_address: z
    .object({
      street: z.string().min(1, "Street is required"),
      city: z.string().min(1, "City is required"),
      state: z.string().min(1, "State is required"),
      country: z.string().min(1, "Country is required"),
      zip: z.string().optional(),
    })
    .refine(
      (data) => {
        // Check if all fields are empty (considering that 'zip' is optional)
        return data.street || data.city || data.state || data.country;
      },
      {
        message: "At least one address field is required",
        path: ["location_address"],
      }
    ),
  location_notes: z.string().min(1, "Location notes are required"),
  customer_id: z
    .number()
    .min(1, "Customer is required")
    .or(z.literal(null).refine(() => false, "Customer is required")),
  emergency_contacts: z
    .array(
      z
        .object({
          id: z.number().optional(),
          type_id: z.number().optional(),
          number: z
            .string()
            .optional()
            .refine(
              (val) => {
                // Check if val is not provided or if it matches the regex
                // This regex matches a string that starts with + followed by 10 to 15 digits
                return !val || /^\+\d{10,15}$/.test(val);
              },
              {
                message:
                  "Invalid phone number format. Enter a number with + and 10 to 15 digits.",
              }
            ),
        })
        .refine(
          (data) => {
            // Ensure both type_id and number are provided if one is given
            return !!data.type_id === !!data.number;
          },
          {
            message:
              "Both type_id and number should be provided if one is given",
            path: ["emergency_contacts"],
          }
        )
    )
    .optional(),
});

export default schema;

function LocationDetailsMeta({
  isOpen,
  toggleOpen,
  updateLocationData,
  isAddMode,
  onSuccess,
  locationData,
}) {
  const params = useParams();
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;

  /// Contacts ///
  const [contacts, setContacts] = useState([
    { id: "", location_id: "", type_id: "", number: "" },
  ]);

  // function formatAddress({ street, city, state, zip, country }) {
  //   // Join the address components into a single string.
  //   return `${street}, ${city}, ${state}, ${zip}, ${country}`;
  // }

  const [submitSuccess, setSubmitSuccess] = useState({
    displaying: false,
    message: "Location updated successfully",
  });

  /// Form ///
  const methods = useForm({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      location_name: locationData?.location_name || "",
      location_address: locationData?.location_address || {
        street: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
      location_notes: locationData?.location_notes || "",
      customer_id: locationData?.customer_id || null,
      emergency_contacts: locationData?.emergency_contacts || [
        { id: "", type_id: "", number: "" },
      ],
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emergency_contacts",
  });

  useEffect(() => {
    if (isOpen && isAddMode) {
      reset({
        location_name: "",
        location_address: {
          street: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        },
        location_notes: "",
        customer_id: null,
      });
    }
  }, [isOpen, isAddMode, reset]);

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const onSubmit = (data) => {
    return isAddMode ? createLocation(data) : updateLocation(data);
  };

  const handleSave = handleSubmit(onSubmit);

  useEffect(() => {
    if (locationData) {
      setValue("location_name", locationData.location_name);
      if (
        locationData.street &&
        typeof locationData.location_address === "object"
      ) {
        setValue(
          "location_address.street",
          locationData.location_address.street
        );
        setValue("location_address.city", locationData.location_address.city);
        setValue("location_address.state", locationData.location_address.state);
        setValue(
          "location_address.country",
          locationData.location_address.country
        );
        setValue("location_address.zip", locationData.location_address.zip);
      } else {
        // Handle case where location_address is not an object, or is missing
        setValue("location_address", {});
      }
      setValue("location_notes", locationData.location_notes);
      setValue("customer_id", locationData.customer_id);
    }
  }, [locationData, setValue]);

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  /// Side sheet ///
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /// Customers ///
  const [customers, setCustomers] = useState([{}]);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/web/admin/customers?org_id=${orgId}`
    )
      .then((respose) => respose.json())
      .then((data) => {
        setCustomers(data);
      });
  }, [orgId]);

  /// Address ///
  const [locationAddress, setLocationAddress] = useState(
    locationData?.location_address || {
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    }
  );

  useEffect(() => {
    if (locationData?.location_address) {
      setLocationAddress(locationData.location_address);
    }
  }, [locationData]);

  const [resetLocationSelected, setResetLocationSelected] = useState(0);

  function setLocationReset() {
    setResetLocationSelected((c) => c + 1); // Increment the counter to trigger change
  }

  /// Fetch emergency contact types ///
  const [contactTypes, setContactTypes] = useState([]);

  useEffect(() => {
    async function fetchContactTypes() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/web/settings/emergency-contacts/types`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setContactTypes(data); // Assuming this state is declared in the parent component
      } catch (error) {
        console.error("Error fetching contact types:", error);
      }
    }
    fetchContactTypes();
  }, []);

  /// Set location id ///
  const location_id = params.id;

  // Fetch and set emergency contacts
  useEffect(() => {
    const fetchContacts = async () => {
      if (!location_id) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/web/settings/emergency-contacts?location_id=${location_id}`
        );
        if (!response.ok)
          throw new Error(`Failed to fetch contacts: ${response.statusText}`);

        const fetchedData = await response.json();
        const formattedContacts = fetchedData.map((contact) => ({
          id: contact.id,
          type_id: contact.type_id,
          number: contact.number.startsWith("+1")
            ? contact.number
            : `+1${contact.number}`,
        }));

        // Set fetched and formatted contacts as the default value
        setValue("emergency_contacts", formattedContacts, {
          shouldValidate: true,
        });

        console.log(
          "Set contacts to:",
          methods.getValues("emergency_contacts")
        );
      } catch (error) {
        console.error("Error fetching emergency contacts:", error);
      }
    };

    fetchContacts();
  }, [location_id, setValue]);

  /// Create location ///
  function createLocation(data) {
    const isContactEmpty = (contact) => {
      return (
        !contact.id ||
        !contact.location_id ||
        !contact.type_id ||
        !contact.number
      );
    };

    const hasEmptyContact = data.emergency_contacts.some((contact) => {
      return !contact.type_id && !contact.number;
    });

    if (hasEmptyContact) {
      setContacts([]);
    }

    const payload = {
      ...data,
      contacts: data.emergency_contacts.map((contact) => ({
        id: contact.id,
        type_id: contact.type_id,
        number: contact.number,
      })),
      customer_id: data.customer_id,
    };

    console.log("Payload:", payload);

    fetch(`${process.env.REACT_APP_API_URL}/v1/locations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(`Fetch returned status ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        onSuccess();
        handleResponse("Location created successfully", "success");
        reset();
        setLocationReset();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to create location", "danger");
      });
  }

  /// Update location ///
  function updateLocation(data) {
    const emergencyContactsPayload = data.emergency_contacts.map((contact) => ({
      id: contact.id,
      type_id: contact.type_id,
      number: contact.number,
    }));

    const payload = {
      ...data,
      org_id: orgId,
      contacts: emergencyContactsPayload,
      customer_id: data.customer_id,
    };

    fetch(`${process.env.REACT_APP_API_URL}/v1/locations/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        onSuccess();
        handleResponse("Location updated successfully", "success");
        // reset();
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update location", "danger");
      });
  }

  return (
    <div>
      <FormProvider {...methods}>
        <SideSheet
          isOpen={open}
          onClose={toggleOpen}
          title={!isAddMode ? "Location details" : "Create location"}
          isAddMode={isAddMode}
          footerText="Create location"
          onError={onError}
          handleSave={handleSave}
        >
          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
            <div className="space-y-6">
              <TextInput name="location_name" label="Location name" />
              <SearchLocationInput
                name="location_address"
                label="Location address"
                defaultValue={locationAddress}
                onAddressChange={(newAddress) => setLocationAddress(newAddress)}
                defaultAddress={locationAddress}
                onReset={resetLocationSelected}
                isAddMode={isAddMode}
              />
              <TextInput name="location_notes" label="Location notes" />
              <Controller
                name="customer_id"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ImageSelectMenu
                    label="Customer"
                    options={customers}
                    value={value}
                    onChange={(selectedId) => {
                      console.log("Selected customer ID:", selectedId);
                      onChange(selectedId);
                    }}
                    error={errors.customer_id}
                  />
                )}
              />
              <EmergencyContacts
                locationId={params.id}
                fields={fields}
                onAddContact={() => append({ type_id: "", number: "" })}
                onRemoveContact={(index) => remove(index)}
                error={errors.emergency_contacts}
                contactTypes={contactTypes}
              />
            </div>
          </div>
        </SideSheet>
      </FormProvider>

      <Toast
        show={toast.show}
        message={toast.message}
        type={toast.type}
        onHide={hideToast}
      />
    </div>
  );
}
export { LocationDetailsMeta };
