import React from "react";

function PrimaryButton({ label, onClick, disabled }) {
  return (
    <div className="col-span-full">
      <button
        onClick={disabled ? undefined : onClick} // Prevent click when disabled
        disabled={disabled} // Set the disabled attribute
        className={`w-full float-right inline-block rounded-md 
          px-6 py-2.5 text-sm font-semibold shadow-sm 
          focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
          transition-colors duration-200
          ${
            disabled
              ? "bg-brand-100 text-gray-50 cursor-not-allowed"
              : "bg-brand-primary hover:bg-brand-200 text-gray-25 hover:text-white focus-visible:outline-brand-100"
          }`}
      >
        {label}
      </button>
    </div>
  );
}

export { PrimaryButton };
