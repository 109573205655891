import React from "react";
import {
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const Banner = ({ title, body, type }) => {
  let icon,
    containerStyles,
    headingStyles,
    bodyStyles = "";

  switch (type) {
    case "error":
      icon = <ExclamationCircleIcon className="pr-6 h-6 w-6 text-red-500" />;
      containerStyles = "bg-brand-50 border border-brand-primary w-2/3";
      headingStyles = "text-gray-500";
      bodyStyles = "text-gray-600";
      break;
    case "success":
      icon = <CheckCircleIcon className="pr-6 h-6 w-6 text-green-500" />;
      containerStyles = "bg-brand-50 border border-brand-primary w-2/3";
      headingStyles = "text-gray-500";
      bodyStyles = "text-gray-600";
      break;
    default:
      icon = (
        <InformationCircleIcon className="mr-4 h-6 w-6 text-brand-primary" />
      );
      containerStyles = "bg-brand-25 border border-brand-primary w-2/3";
      headingStyles = "text-gray-600";
      bodyStyles = "text-gray-700";
      break;
  }

  return (
    <div className={`p-4 rounded-md flex ${containerStyles}`}>
      {icon}
      <div>
        {title && (
          <h1 className={`text-lg font-bold ${headingStyles}`}>{title}</h1>
        )}
        {body && <p className={`${bodyStyles}`}>{body}</p>}
      </div>
    </div>
  );
};

export { Banner };
