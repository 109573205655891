import React, { useContext, useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logo-dark.svg";
import { PrimaryButton } from "../../../components/button/primary";
import { AuthContext } from "../../../contexts/AuthContext"; // Import auth context

function Login() {
  const { login, isAuthenticated, getToken, isLoading } = useKindeAuth();
  const location = useLocation();

  const handleClick = async () => {
    await login({
      app_state: {
        redirectTo: location.state ? location.state.from.pathname : "/",
      },
    });
  };

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 md:px-18 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="pt-12 w-full">
          {!isAuthenticated && (
            <PrimaryButton label="Sign in" onClick={handleClick} />
          )}
        </div>
      </div>
    </div>
  );
}

export { Login };
