const FormatPrice = (amount, currency = "USD") => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
  });

  return formatter.format(amount);
};

export default FormatPrice;
