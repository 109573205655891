import { useContext, useEffect } from "react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
// import { CustomerSelector } from "../menu/customer";
import { UserProfile } from "../profile";
import logo from "../../assets/logo.svg";
import {
  Bars3Icon,
  ClipboardDocumentCheckIcon,
  RectangleGroupIcon,
  XMarkIcon,
  Cog6ToothIcon,
  ChevronDownIcon,
  BuildingOfficeIcon,
  MapPinIcon,
  UserIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "../../contexts/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation(props) {
  const { authContext, logout } = useContext(AuthContext);

  const navOptions = [
    {
      name: "Dashboard",
      href: "/",
      icon: RectangleGroupIcon,
      current: props.current === "dashboard" ? true : false,
    },
    {
      name: "Reporting",
      href: "/reporting",
      icon: ClipboardDocumentCheckIcon,
      current: props.current === "reporting" ? true : false,
    },
    {
      name: "Customers",
      href: "/customers",
      icon: UserIcon,
      current: props.current === "customers" ? true : false,
    },
    {
      name: "Locations",
      href: "/locations",
      icon: MapPinIcon,
      current: props.current === "locations" ? true : false,
    },
    // {
    //   name: "Users",
    //   href: "/users",
    //   icon: UserCircleIcon,
    //   current: props.current === "users" ? true : false,
    // },
  ];

  const userNavigation = [
    { name: "Your profile", type: "link", href: "/profile/" },
    { name: "Logout", type: "function", onClick: logout },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 pt-12 shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="h-6 w-auto text-center"
                        src={logo}
                        alt="Opspot"
                      />
                    </Link>
                  </div>
                  <div className="pt-5">
                    <BuildingOfficeIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                    <p className="pl-3">{authContext.organization.orgName}</p>
                  </div>
                  <nav className="flex flex-1 flex-col -mt-2">
                    <ul role="list" className="flex flex-1 flex-col">
                      <li>
                        <ul role="list" className="">
                          {navOptions.map((item) => (
                            <li
                              key={item.name}
                              className={classNames(
                                item.current
                                  ? "border-l-[2px] border-brand-secondary"
                                  : "",
                                "flex items-center"
                              )}
                            >
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "text-white font-medium"
                                    : "text-gray-300 hover:text-gray-200",
                                  "group flex items-center gap-x-3 pl-4 p-2 m-1 ml-2 text-sm leading-6 font-light" // 'items-center' added here
                                )}
                              >
                                <item.icon
                                  className="h-5 w-5 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col overflow-y-auto bg-black">
          <div className="flex pt-7 pb-16 items-center ml-8">
            <a href="/">
              <img className="h-6 w-auto text-center" src={logo} alt="Opspot" />
            </a>
          </div>

          <div className="flex items-center pl-8 pb-4">
            <BuildingOfficeIcon
              className="h-5 w-5 text-gray-100"
              aria-hidden="true"
            />
            <p className="pl-2.5 text-gray-100">
              {authContext.organization.orgName}
            </p>
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col">
              <li>
                <ul role="list" className="">
                  {navOptions.map((item) => (
                    <li
                      key={item.name}
                      className={classNames(
                        item.current
                          ? "border-l-[2px] border-brand-secondary"
                          : "",
                        "flex items-center"
                      )}
                    >
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "text-white font-medium"
                            : "text-gray-100 hover:text-gray-300 transition-colors duration-200",
                          "group flex items-center gap-x-3 pl-6 p-1 m-1 ml-2 text-sm leading-6 font-light w-full"
                        )}
                      >
                        <item.icon
                          className={classNames("h-5 w-5 shrink-0 ")}
                          aria-hidden="true"
                        />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="lg:px-8">
          <div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-100 bg-white lg:py-10">
            <button
              type="button"
              className="m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1 items-center">
                <div>
                  {props.subHeading ? (
                    <p className="text-xsm font-normal text-gray-500">
                      {props.subHeading}
                    </p>
                  ) : null}
                  <h1 className="text-2xl font-bold">
                    {props.heading ? props.heading : "Heading"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div className="border-gray-200 border-r pr-4">
                  <Link
                    to="/settings/organization"
                    className="flex items-center gap-x-2 text-gray-500 hover:text-gray-800 transition-colors duration-200"
                  >
                    <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                </div>
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center text-gray-400 hover:text-gray-700 duration-200 transition-colors">
                    <span className="sr-only">Open user menu</span>
                    {
                      <UserProfile
                        imageUrl={authContext.user.imageUrl}
                        size="small"
                        firstName={authContext.user.firstName}
                        lastName={authContext.user.lastName}
                        useInitials={false}
                      />
                    }
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="text-sm font-medium leading-6 text-gray-600"
                        aria-hidden="true"
                      ></span>
                      <ChevronDownIcon
                        className="-ml-1 h-3 w-3"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <>
                              {item.type === "link" && (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-3 py-1 text-sm leading-6 text-black cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}

                              {item.type === "function" && (
                                <button
                                  onClick={item.onClick} // Use onClick directly here
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-3 py-1 text-sm leading-6 text-black cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
