import React, { useEffect } from "react";
import logo from "../../../assets/logo-dark.svg";
import { PrimaryButton } from "../../../components/button/primary";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../../contexts/AuthContext";

const Unauthorized = () => {
  const { isAuthenticated, logout, user } = useKindeAuth();
  const { authContext } = React.useContext(AuthContext);

  useEffect(() => {
    console.log("AUTH", authContext);
  }, [authContext]);

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 md:px-18 lg:px-12 sm:py-8 lg:py-12 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="pt-12 w-full">
          <p className="pb-8 text-center leading-8">
            {authContext.user.email} <br />
            you do not have permission to view this page :-(
          </p>
          {isAuthenticated && <PrimaryButton label="Logout" onClick={logout} />}
        </div>
      </div>
    </div>
  );
};

export { Unauthorized };
