import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import Navigation from "../../components/navigation";
import { Tabs } from "../../components/tabs";

import { AuthContext } from "../../contexts/AuthContext";

import { PageLoadingAnimation } from "../../components/loading/pageLoading";

function Scheduling() {
  const { authContext } = useContext(AuthContext);

  const [isPageLoading, setIsPageLoading] = useState(true);

  setTimeout(() => {
    setIsPageLoading(false);
  }, 1200);

  return (
    <div>
      <Navigation heading="Scheduling" current="scheduling" />
      {/* <Tabs current="Activity" section="reporting" /> */}
      <main className="py-6 lg:pl-72">
        <PageLoadingAnimation isLoading={isPageLoading} />
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-4"></div>
        </div>
      </main>
    </div>
  );
}

export { Scheduling };
