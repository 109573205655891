import React, { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Table } from "../../../components/table";
import { TableLoadingAnimation } from "../../../components/loading/tableLoading";
import emptyTour from "../../../assets/icons/empty-tour.svg";
import { LinkButton } from "../../../components/button/link";

function Tours({
  handleToursAddMode,
  setIsCheckpointsAddMode,
  fetchToursList,
  isLoading,
  handleDeleteTour,
  toursListData,
}) {
  useEffect(() => {
    fetchToursList();
  }, []);

  useEffect(() => {
    console.log("toursListData", toursListData);
  }, [toursListData]);

  /// Table columns ///
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "tour_name",
        // Use a custom cell to render the link
        Cell: ({ row }) => (
          <div>
            <a
              onClick={() => {
                handleToursAddMode(row.original.id);
                setIsCheckpointsAddMode(true);
              }}
              className="text-gray-500 hover:text-gray-600 hover:underline cursor-pointer"
            >
              {row.values.tour_name}
            </a>
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "tour_description",
      },
      {
        Header: "Checkpoints",
        accessor: "checkpoint_names",
      },
      {
        Header: "",
        id: "delete",
        accessor: (str) => "delete",
        Cell: ({ row }) => (
          <button
            onClick={() =>
              handleDeleteTour(row.original.tour_name, row.original.id)
            }
            className="text-gray-600 hover:text-gray-500"
          >
            <TrashIcon className="h-4 w-4 shrink-0" />
          </button>
        ),
      },
    ],
    []
  );

  return (
    <div>
      {isLoading || toursListData === undefined ? (
        <TableLoadingAnimation isLoading={isLoading} />
      ) : toursListData.length > 0 ? (
        <Table data={toursListData} columns={columns} />
      ) : (
        <div className="flex flex-col items-center justify-center h-96">
          <img src={emptyTour} className="text-black w-36 h-36" />
          <p className="mt-0 text-gray-300 text-center text-md ">
            No tours yet
          </p>
          {/* <LinkButton
            type="function"
            text="Create checkpoint"
            onClick={() => {
              setIsCheckpointsAddMode(true);
              toggleOpen();
            }}
          /> */}
        </div>
      )}
    </div>
    /* {typeof toursListData === "undefined" ? (
        <p>Loading...</p>
      ) : (
        <table className="mt-2 w-full">
          <thead className="text-xs uppercase text-left">
            <tr>
              <th className="pl-6 font-normal text-gray-500">Name</th>
              <th className="pl-6 font-normal text-gray-500">Description</th>
              <th className="pl-6 font-normal  text-gray-500">Checkpoints</th>
              <th className="pl-6 font-normal text-gray-500">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {toursListData.map((tour) => (
              <tr
                className="border-solid border-b border-gray-100"
                key={tour.id}
              >
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm ">
                  <a
                    onClick={() => {
                      handleToursAddMode(tour.id);
                      setIsCheckpointsAddMode(true);
                    }}
                    className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
                  >
                    {tour.tour_name}
                  </a>
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {tour.tour_description}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-sm text-gray-500">
                  {tour.checkpoint_names}
                </td>
                <td className="py-4 pl-6 pr-2 whitespace-nowrap text-right text-sm font-medium  hover:text-gray-600">
                  <TrashIcon className="h-4 w-4 shrink-0 hover:cursor-pointer hover:text-gray-600" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )} */
  );
}

export { Tours };
