import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../contexts/AuthContext";
import Navigation from "../components/navigation/index";
import { PageLoadingAnimation } from "../components/loading/pageLoading";

const ProtectedRoute = ({
  children,
  requiredRole = false,
  allowInitialization = false,
  navigationProps,
}) => {
  const { isAuthenticated, isLoading } = useKindeAuth();
  const { authContext, authContextLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return; // Wait until 'isLoading' is false

    if (!isAuthenticated) {
      navigate("/auth/login/");
      return;
    }

    if (authContextLoading) return; // Wait until 'authContextLoading' is false

    // Check if organization initialization is complete
    if (
      !authContext.organization.initializationComplete &&
      !allowInitialization
    ) {
      navigate("/settings/organization/");
      return;
    }

    // Role-Based Access Control
    if (requiredRole) {
      const hasRequiredRole = authContext.user.roles.some(
        (role) => role.key === requiredRole
      );

      if (!hasRequiredRole) {
        navigate("/auth/unauthorized/"); // Or show an unauthorized page
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    authContextLoading,
    authContext.organization.initializationComplete,
    allowInitialization,
    requiredRole,
    authContext.user.roles,
    navigate,
  ]);

  useEffect(() => {
    console.log("AuthContext:", authContext);
  }, [authContext]);

  if (isLoading || authContextLoading) {
    // Include Navigation to keep layout consistent
    return (
      <>
        {/* <Navigation {...navigationProps} /> */}
        <PageLoadingAnimation
          isLoading={isLoading || authContextLoading}
          navSpacer={true}
          nonAnimated={true}
        />
      </>
    );
  }

  return (
    <>
      {authContext.organization.initializationComplete === 1 && (
        <Navigation {...navigationProps} />
      )}
      {children}
    </>
  );
};

export default ProtectedRoute;
