import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../../components/button";
import { SuccessToast } from "../../../components/toast/success";
import { SideSheet } from "../../../components/sheet/sideSheet";

function SelectCheckpoints({
  isOpen,
  toggleOpen,
  existingCheckpoints,
  tourId,
  handleSetCheckpoints,
}) {
  const params = useParams();

  /// Manage existing and setting checkpoints ///
  const [localCheckpoints, setLocalCheckpoints] = useState([]);
  useEffect(() => {
    setLocalCheckpoints(existingCheckpoints);
  }, [existingCheckpoints]);

  const handleCheckboxChange = (checkpointId) => {
    setLocalCheckpoints((currentCheckpoints) => {
      const index = currentCheckpoints.findIndex((e) => e.id === checkpointId);
      if (index !== -1) {
        // If found, we remove the object to uncheck it.
        return currentCheckpoints.filter((_, i) => i !== index);
      } else {
        // If not found, we add the new checkpoint object to check it.
        const newCheckpoint = checkpoints.find((e) => e.id === checkpointId);
        return [...currentCheckpoints, newCheckpoint];
      }
    });
  };

  /// Get all checkpoints for the location ///
  const [checkpoints, setCheckpoints] = useState([]);
  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/locations/${params.id}/checkpoints`
      );
      if (response.ok) {
        const data = await response.json();
        setCheckpoints(data);
      } else {
        // Handle error
        console.error("Error fetching checkpoints");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching checkpoints", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [useParams.id]);

  /// Handle add ///

  const handleAdd = () => {
    // Find the maximum position among the existing checkpoints
    const maxPosition = existingCheckpoints.reduce(
      (max, checkpoint) =>
        checkpoint.position > max ? checkpoint.position : max,
      0
    );

    // Counter for new checkpoints
    let newPositionCounter = maxPosition;

    // Map localCheckpoints to the required structure
    const updatedCheckpoints = localCheckpoints.map(
      ({
        id,
        checkpoint_name,
        checkpoint_description,
        position,
        type_name,
      }) => {
        // Check if this checkpoint is a new addition
        const isNewCheckpoint = !existingCheckpoints.some((e) => e.id === id);

        return {
          id,
          checkpoint_name,
          checkpoint_description,
          position: isNewCheckpoint ? ++newPositionCounter : position, // Increment position counter for new checkpoints
          type_name,
        };
      }
    );

    // Call handleSetCheckpoints with the updated checkpoints
    handleSetCheckpoints(updatedCheckpoints);

    toggleOpen();
  };

  return (
    <SideSheet
      isOpen={isOpen}
      onClose={toggleOpen}
      handleButtonClick={handleAdd}
      title="Add checkpoints"
    >
      <div className="px-4 sm:px-6">
        <div>
          <fieldset>
            <div className="mt-2">
              {checkpoints.map((checkpoint) => (
                <div
                  key={checkpoint.id}
                  className="-ml-4 flex items-center hover:bg-gray-50 border-gray-100 border-b"
                >
                  <input
                    id={`checkpoint-${checkpoint.id}`}
                    name="checkpoint"
                    aria-describedby={`checkpoint-description-${checkpoint.id}`}
                    type="checkbox"
                    className="ml-4 my-2 focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    value={checkpoint.id}
                    checked={localCheckpoints.some(
                      (e) => e.id === checkpoint.id
                    )}
                    onChange={() => handleCheckboxChange(checkpoint.id)}
                  />
                  <label
                    htmlFor={`checkpoint-${checkpoint.id}`}
                    className="pl-4 py-4 text-sm text-gray-900 flex flex-grow"
                  >
                    {checkpoint.checkpoint_name}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>
    </SideSheet>
  );
}

export { SelectCheckpoints };
