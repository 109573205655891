import React from "react";

function UserProfile({ imageUrl, size, firstName, lastName, useInitials }) {
  let imageSize;

  switch (size) {
    case "xsmall":
      imageSize = "h-6 w-6";
      break;
    case "small":
      imageSize = "h-8 w-8";
      break;
    case "medium":
      imageSize = "h-10 w-10";
      break;
    case "large":
      imageSize = "h-14 w-14";
      break;
    default:
      imageSize = "h-10 w-10";
      break;
  }

  let initials =
    useInitials && firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";

  // Render the image if imageUrl is present
  if (imageUrl && imageUrl.length > 0) {
    return (
      <img
        src={imageUrl}
        alt={`${firstName} ${lastName}`}
        className={`mr-3 rounded-full object-cover bg-gray-50 inline-block align-middle ${imageSize}`}
      />
    );
  }

  // Render the initials (or bg) if imageUrl is not present
  return (
    <div
      className={`mr-3 rounded-full bg-gray-100 inline-flex justify-center items-center text-center text-gray-600 text-md font-bold ${imageSize}`}
    >
      {initials}
    </div>
  );
}

export { UserProfile };
