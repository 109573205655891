import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    getToken,
    user,
    getPermission,
    logout,
    getClaim,
  } = useKindeAuth();
  const navigate = useNavigate();
  const maxRetries = 5;
  const retryDelay = 1500;

  const initialAuthContext = {
    user: {
      userId: "",
      authId: "",
      accessLevel: 1,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      imageUrl: "",
      roles: [],
    },
    organization: {
      authOrgId: "",
      orgId: "",
      orgName: "",
      stripeCustomerId: "",
      initializationComplete: "",
      planId: "",
      planStatus: "",
      planName: "",
      planTrialEnd: "",
      planFeatures: {},
    },
  };

  const [authContext, setAuthContext] = useState(initialAuthContext);
  const [authContextLoading, setAuthContextLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  /**
   * Reset the `authContext` to its initial state.
   */
  const resetAuthContext = () => {
    setAuthContext(initialAuthContext);
  };

  /**
   * Process user and organization data and update `authContext`.
   * @param {Object} data - Fetched user data.
   */
  const processUserData = useCallback(
    (data) => {
      if (!data) {
        console.error("Data is undefined");
        return;
      }

      const rolesClaim = getClaim("roles");
      const roles = rolesClaim?.value || [];

      const newAuthContext = {
        user: {
          userId: data.id,
          authId: data.auth_id || data.id,
          accessLevel: "1",
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phoneNumber: data.phone_number,
          imageUrl: data.profile_url,
          roles,
        },
        organization: {
          authOrgId: getPermission()?.orgCode || "",
          orgId: data.org_id,
          orgName: data.org_name,
          stripeCustomerId: data.stripe_customer_id,
          initializationComplete: data.initialization_complete,
          planId: data.subscription.id || "",
          planStatus: data.subscription.status || "",
          planName: data.subscription.name || "",
          planTrialEnd: data.subscription.trialEnd || "",
          planFeatures: data.subscription.features || {},
        },
      };

      setAuthContext(newAuthContext);
    },
    [getPermission, getClaim]
  );

  /**
   * Fetch user data when authenticated.
   */
  const fetchUserData = useCallback(async () => {
    if (!isAuthenticated || isLoading || !user) {
      console.log("fetchUserData exiting early");
      return;
    }

    try {
      console.log("Fetching token...");
      const token = await getToken();
      console.log("Token acquired:", token);
      console.log("Making API call...");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/auth/login/${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch user data. Status: ${response.status}`
        );
      }

      const data = await response.json();
      processUserData(data);
    } catch (error) {
      console.error("Error fetching user data:", error);

      // Retry logic
      if (retryCount < maxRetries) {
        console.log(`Retrying in ${retryDelay * (retryCount + 1)} ms...`);
        setTimeout(
          () => setRetryCount((prev) => prev + 1),
          retryDelay * (retryCount + 1)
        );
      } else {
        console.error("Max retries reached. Could not fetch user data.");
      }
    } finally {
      setAuthContextLoading(false);
    }
  }, [
    isAuthenticated,
    isLoading,
    getToken,
    user,
    processUserData,
    retryCount,
    maxRetries,
    retryDelay,
  ]);

  /**
   * Handle logout and reset `authContext`.
   */
  const handleLogout = useCallback(async () => {
    try {
      resetAuthContext();
      await logout();
      navigate("/auth/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [logout, navigate]);

  /**
   * Fetch user data on component mount or changes in authentication state.
   */
  useEffect(() => {
    if (isAuthenticated && !isLoading && user) {
      fetchUserData();
    }
  }, [isAuthenticated, isLoading, user, fetchUserData]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        getToken,
        authContext,
        authContextLoading,
        setAuthContext,
        logout: handleLogout,
        fetchUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
