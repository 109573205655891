import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const AuthCallback = () => {
  const { isAuthenticated, isLoading } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      navigate(isAuthenticated ? "/" : "/auth/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  return <div>Authenticating...</div>; // Minimal indication of the process
};

export { AuthCallback };
